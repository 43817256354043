import imageCompression from 'browser-image-compression';
import Jimp from 'jimp';
import { ActionType } from '../context/actions';
import {
  MediaId,
  MediaSection,
  ValidationError,
} from '../context/photoConstants';
import { CONNECT_SERVICES_CLIENT } from './ConnectServicesClient';
import HeimdallClient from './HeimdallClient';
import { normalizePlate } from '../context/reducer';
import { compareStrings } from './Utils';
import CpeClient, { Angles, Categories, UploadImageResponse } from './CPE';
import {
  AzureCustomVisionProvider,
  PredictionResult,
} from './AzurePredictionsClient';

function getFileExtension(type: string) {
  if (type.includes('png')) {
    return '.png';
  } else if (type.includes('jpg')) {
    return '.jpg';
  } else if (type.includes('jpeg')) {
    return '.jpeg';
  } else if (type.includes('mp4')) {
    return '.mp4';
  } else {
    console.warn('Unknown extension', type);
    const guess = type.split('/')[1];

    return '.' + (guess || 'file'); // try our best effort, else fallback to .file
  }
}

export async function uploadPhotoToServer(
  preInspectionId: string,
  mediaId: MediaId,
  event: React.ChangeEvent<HTMLInputElement>,
  skipValidation?: boolean,
): Promise<{ awsUrl: string; dataUrl: string; original: File }> {
  if (event.target.files === null) {
    throw Error('Invalid parameter');
  }

  console.time('Compressing Image');
  const imageFile = event.target.files[0];
  const compressedFile = await imageCompression(imageFile, {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  });
  const dataUrl = await imageCompression.getDataUrlFromFile(compressedFile);
  console.timeEnd('Compressing Image');

  const fileExtension = getFileExtension(imageFile.type);
  const filename = `${mediaId}${fileExtension}`;
  let prefix = '';

  if (mediaId === MediaId.DOCUMENTS_VEHICLE_PROPERTY_REGISTRY) {
    prefix = MediaSection['RUV'].toString() + '_';
  } else {
    Object.keys(MediaSection).forEach((key: any) => {
      if (filename.toUpperCase().indexOf(String(key)) > -1) {
        prefix = MediaSection[key].toString() + '_';
      }
    });
  }

  // Validate metatag
  const metaTag =
    skipValidation !== undefined && skipValidation
      ? 'PHOTO_NO_VALID'
      : undefined;

  console.time('Uploading Image');
  const response = await CONNECT_SERVICES_CLIENT.uploadMedia(
    preInspectionId,
    prefix + filename,
    fileExtension,
    compressedFile,
    metaTag,
  );
  const awsUrl = response.data.data;
  console.timeEnd('Uploading Image');
  if (!awsUrl) {
    throw new Error('Error uploading photo');
  }

  return { awsUrl, dataUrl: dataUrl, original: imageFile };
}

export async function uploadMediaToServer(
  preInspectionId: string,
  mediaId: MediaId,
  file: File,
): Promise<string> {
  const fileExtension = getFileExtension(file.type);
  const filename = `${mediaId}${fileExtension}`;
  let prefix = '';

  Object.keys(MediaSection).forEach((key: any) => {
    if (filename.toUpperCase().indexOf(String(key)) > -1) {
      prefix = MediaSection[key].toString() + '_';
    }
  });

  const response = await CONNECT_SERVICES_CLIENT.uploadMedia(
    preInspectionId,
    prefix + filename,
    fileExtension,
    file,
  );
  const awsUrl = response.data.data;
  if (!awsUrl) {
    throw new Error('Error uploading photo');
  }

  return awsUrl;
}

export async function uploadBase64ToServer(
  preInspectionId: string,
  base64File: string,
  fileName: string,
  fileExtension: string,
): Promise<string> {
  const response = await CONNECT_SERVICES_CLIENT.uploadMediaBase64(
    preInspectionId,
    fileName,
    fileExtension,
    base64File,
  );
  const awsUrl = response.data.data;
  if (!awsUrl) {
    throw new Error('Error uploading photo');
  }

  return awsUrl;
}

export const dataUrl2Blob = (dataUrl: string): Blob => {
  /* eslint-disable */
  // Yes. This block was pasted from stackoverflow.
  const arr = dataUrl?.split(',');
  const mime = arr![0]?.match(/:(.*?);/)![1];
  const bstr = atob(arr![1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
};

/**
 * Makes requests to Heimdall as needed.
 * @returns validation error (or null if image passed validations) and
 *  a state-mutation redux action as per extraction results.
 **/
export async function runValidations(
  mediaId: MediaId,
  awsUrl: string,
  inactiveProcess: boolean,
  plate: string,
  requireMatch: boolean,
  originFile: File,
  forceUpload: boolean,
): Promise<[ValidationError | null, any | null]> {
  if (inactiveProcess) {
    return [null, null];
  }

  const client = new HeimdallClient();
  if (
    [MediaId.DOCUMENTS_CEDULA, MediaId.DOCUMENTS_DRIVERS_LICENSE].includes(
      mediaId,
    )
  ) {
    const result = await client.isIdOrLicense(awsUrl);
    return [result ? null : ValidationError.NO_DOCUMENT, null];
  } else if (mediaId === MediaId.DOCUMENTS_VEHICLE_PROPERTY_REGISTRY) {
    // First check if its a document, then extract values from RUV
    const result = await client.isDocument(awsUrl);
    if (!result) {
      return [ValidationError.NO_DOCUMENT, null];
    }

    const extractedData = await client.extractRUV(awsUrl);
    console.log('RUV-ExtractedData::', extractedData);
    return [
      null,
      { type: ActionType.SET_EXTRACTED_RUV, payload: extractedData },
    ];
  } else if (
    [
      MediaId.VEHICLE_EXTERIOR_FRONT,
      MediaId.VEHICLE_EXTERIOR_RIGHT,
      MediaId.VEHICLE_EXTERIOR_RIGHT_FRONT,
      MediaId.VEHICLE_EXTERIOR_RIGHT_BACK,
      MediaId.VEHICLE_EXTERIOR_BACK,
      MediaId.VEHICLE_EXTERIOR_LEFT,
      MediaId.VEHICLE_EXTERIOR_LEFT_FRONT,
      MediaId.VEHICLE_EXTERIOR_LEFT_BACK,
    ].includes(mediaId)
  ) {
    // Validation vehicles angles cpe
    const resultCpe = await CheckValidationCpe(
      mediaId,
      originFile,
      forceUpload,
    );
    console.log('### RESULT CPE ###', resultCpe);

    if (!resultCpe) {
      return [ValidationError.NO_VEHICLE, null];
    }
  } else if (
    mediaId === MediaId.VEHICLE_EXTERIOR_PLATE ||
    mediaId === MediaId.VEHICLE_EXTERIOR_PLATE_CR
  ) {
    const result = await client.extractPlate(awsUrl);
    if (result) {
      const normalizedPlate =
        plate && plate !== '' ? normalizePlate(plate) : null;
      const normalizedResult = normalizePlate(result);

      if (requireMatch && normalizedPlate) {
        if (compareStrings(normalizedPlate, normalizedResult, 75)) {
          return [
            null,
            { type: ActionType.SET_EXTRACTED_PLATE, payload: result },
          ];
        } else {
          return [ValidationError.NO_PLATE_MATCH, null];
        }
      }

      return [null, { type: ActionType.SET_EXTRACTED_PLATE, payload: result }];
    } else {
      return [ValidationError.NO_PLATE, null];
    }
  } else if (mediaId === MediaId.VEHICLE_INTERIOR_VIN) {
    const result = await client.extractVin(awsUrl);
    if (result) {
      return [null, { type: ActionType.SET_EXTRACTED_VIN, payload: result }];
    } else {
      // Allow user to pass, since our validation still needs work
      return [null, null];
    }
  } else if (
    mediaId === MediaId.VEHICLE_INTERIOR_1 ||
    mediaId === MediaId.VEHICLE_INTERIOR_2
  ) {
    const result = await client.isVehicleInternal(awsUrl);

    return [result ? null : ValidationError.NO_VEHICLE, null];
  } else if (mediaId === MediaId.VEHICLE_INTERIOR_ODOMETER) {
    const result = await client.isOdometer(awsUrl);

    return [result ? null : ValidationError.NO_ODOMETER, null];
  }
  return [null, null];
}

export const CheckPredictionsAzure = async (
  file: File,
): Promise<PredictionResult[]> => {
  const arrayBuffer = await file.arrayBuffer();
  const buffer = Buffer.from(arrayBuffer);

  const clientAzure = new AzureCustomVisionProvider();

  const resultPredictions = await clientAzure.getPrediction(buffer);

  return resultPredictions;
};

// Request API Cpe Validation AI
export const CheckValidationCpe = async (
  mediaId: MediaId,
  imageOrigin: File,
  forceUpload: boolean,
): Promise<boolean> => {
  const cpeClient = new CpeClient();

  const mediaIdMapping: Record<any, { category: Categories }> = {
    [MediaId.VEHICLE_EXTERIOR_FRONT]: { category: Categories.VEHICLE },
    [MediaId.VEHICLE_EXTERIOR_RIGHT]: { category: Categories.VEHICLE },
    [MediaId.VEHICLE_EXTERIOR_RIGHT_FRONT]: { category: Categories.VEHICLE },
    [MediaId.VEHICLE_EXTERIOR_RIGHT_BACK]: { category: Categories.VEHICLE },
    [MediaId.VEHICLE_EXTERIOR_BACK]: { category: Categories.VEHICLE },
    [MediaId.VEHICLE_EXTERIOR_LEFT]: { category: Categories.VEHICLE },
    [MediaId.VEHICLE_EXTERIOR_LEFT_FRONT]: { category: Categories.VEHICLE },
    [MediaId.VEHICLE_EXTERIOR_LEFT_BACK]: { category: Categories.VEHICLE },
    [MediaId.VEHICLE_EXTERIOR_PLATE]: { category: Categories.LICENSE_PLATE },
    [MediaId.VEHICLE_EXTERIOR_PLATE_CR]: { category: Categories.LICENSE_PLATE },
    [MediaId.DOCUMENTS_DRIVERS_LICENSE]: {
      category: Categories.DRIVER_LICENSE,
    },
    [MediaId.VEHICLE_INTERIOR_ODOMETER]: { category: Categories.ODOMETER },
    [MediaId.VEHICLE_INTERIOR_VIN]: { category: Categories.VIN_NUMBER },
  };

  const validationConfig = mediaIdMapping[mediaId];

  if (!validationConfig) {
    console.warn('Unknown validation', mediaId);

    return true;
  }

  try {
    const result = await cpeClient.sendImageValidation(
      [imageOrigin],
      validationConfig.category,
      forceUpload,
    );

    console.log('Results Cpe:', result);

    //if force upload is true don't parse results
    if (forceUpload) {
      return true;
    }

    return await parseResultsCpe(mediaId, validationConfig.category, result);
  } catch (error) {
    console.error('Error during validation:', error);
    localStorage.removeItem(`cpe-${mediaId}`);
    return false;
  }
};

export const parseResultsCpe = async (
  mediaId: MediaId,
  category: Categories,
  result: UploadImageResponse,
): Promise<boolean> => {
  switch (category) {
    case Categories.VEHICLE: {
      const anglesMapping: Record<any, Angles> = {
        [MediaId.VEHICLE_EXTERIOR_FRONT]: Angles.front,
        [MediaId.VEHICLE_EXTERIOR_RIGHT]: Angles.right,
        [MediaId.VEHICLE_EXTERIOR_RIGHT_FRONT]: Angles.rightFront,
        [MediaId.VEHICLE_EXTERIOR_RIGHT_BACK]: Angles.rearRight,
        [MediaId.VEHICLE_EXTERIOR_BACK]: Angles.rear,
        [MediaId.VEHICLE_EXTERIOR_LEFT]: Angles.left,
        [MediaId.VEHICLE_EXTERIOR_LEFT_FRONT]: Angles.leftFront,
        [MediaId.VEHICLE_EXTERIOR_LEFT_BACK]: Angles.rearLeft,
      };

      // Implement specific logic
      const angle = anglesMapping[mediaId];
      if (result.angle !== angle) {
        throw new Error('Angle Incorrect');
      }

      return true;
    }
    case Categories.LICENSE_PLATE: {
      const plateResults = result;
      console.log('Parsed License Plate Results:', plateResults);
      return true; // Implement specific logic
    }
    case Categories.DRIVER_LICENSE: {
      const licenseResults = result;
      console.log('Parsed Driver License Results:', licenseResults);
      return true; // Implement specific logic
    }
    case Categories.MARBETE: {
      const marbeteResults = result;
      console.log('Parsed Marbete Results:', marbeteResults);
      return true; // Implement specific logic
    }
    case Categories.VIN_NUMBER: {
      const vinResults = result;
      console.log('Parsed VIN Results:', vinResults);
      return true; // Implement specific logic
    }
    default: {
      console.warn('Unknown category for parsing', category);
      return true;
    }
  }
};

export async function deletePhotoFromServer(imageUrl: string): Promise<void> {
  await CONNECT_SERVICES_CLIENT.deleteImage(imageUrl);
}

async function transformToInvertImage(currentFile: File): Promise<string> {
  const buffer: any = await currentFile.arrayBuffer();
  const readImage = await Jimp.read(buffer);
  const convert = readImage.quality(30).invert().brightness(-0.09);

  return await convert.getBase64Async(Jimp.MIME_JPEG);
}
