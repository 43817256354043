import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ContinueButton from '../components/carousel/ContinueButton';
import {
  MediaId,
  photoSectionIdFromUrlSlug,
  PHOTO_DEFINITIONS,
  PHOTO_SECTIONS,
  FILTER_PHOTO_SECTIONS,
  ValidationError,
  PhotoSectionId,
  isValidPhotoSectionsByBrand,
} from '../context/photoConstants';
import { BRAND } from '../static/constants/brands/brand';
import { IAppContext, IState, Photo, VehicleSide } from '../types';
import { AppContext } from '../context/Context';
import {
  deletePhotoFromServer,
  runValidations,
  uploadPhotoToServer,
} from '../helpers/photoUploader';
import { ActionType } from '../context/actions';
import ValidationWarning from '../components/ValidationWarning';
import { ReactComponent as BackIcon } from '../assets/imgs/back.svg';
import { ReactComponent as InfoIcon } from '../assets/imgs/i-info.svg';
import { ReactComponent as ViewIcon } from '../assets/imgs/i-view.svg';
import Modal from '../components/Modal';
import {
  getHelpModalContents,
  refreshGalleryOneScreen,
} from '../helpers/photoHelper';
import ProgressLoading from '../components/common/ProgressLoading';
import {
  getIsValidZones,
  sendToDamageAnalysis,
} from '../services/preInspect.service';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Snackbar,
} from '@mui/material';

import clsx from 'clsx';
import './PhotoSectionScreen.scss';
import { AlertProps } from '@mui/lab/Alert';
type AlertType = 'success' | 'info' | 'warning' | 'error' | string;
import { Company } from '../interfaces/brand';
import { v4 as uuidv4 } from 'uuid';
import { isNetOnline } from '../helpers/Utils';

function ImagePreviewThumbnail({
  photo,
  onPreviewClick,
}: {
  photo: Photo;
  onPreviewClick?: any;
}): JSX.Element {
  const photoDefinition = PHOTO_DEFINITIONS[photo.id];

  return (
    <div className="image-preview-thumbnail">
      <img
        className={photo.notValidZone ? 'image-preview-not-valid' : ''}
        src={photo.url}
        alt={photoDefinition.title}
        onClick={() => onPreviewClick(photo)}
      />
      {photoDefinition.shortTitle && (
        <div
          className={clsx(
            'preview-label',
            photo.notValidZone ? 'preview-label-not-valid' : '',
          )}
        >
          {photoDefinition.shortTitle}
        </div>
      )}
    </div>
  );
}

function ImageUploadThumbnail({
  photo,
  onChange,
  onHelpClick,
  media,
  hasItems = true,
  hasExampleIcon = true,
  setIsExample,
}: {
  photo?: Photo;
  onChange?: any;
  onHelpClick?: any;
  media?: MediaId;
  hasItems?: boolean;
  hasExampleIcon?: boolean;
  setIsExample: any;
}): JSX.Element {
  const photoId = photo?.id ?? media;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const photoDefinition = PHOTO_DEFINITIONS[photoId!];
  const htmlId = `photo-${photoId}`;

  return (
    <div
      className={clsx(
        'image-upload-thumbnail',
        !hasItems ? 'single-item-thubmanil-width' : '',
      )}
    >
      <label
        htmlFor={hasExampleIcon ? undefined : htmlId}
        className="custom-file-upload"
        onClick={() => {
          {
            setIsExample(false);
            onHelpClick(photoId);
          }
        }}
      >
        {photoDefinition?.placeholder}
        {photoDefinition.title}
      </label>
      {hasExampleIcon ? (
        <div
          className="ayuda"
          onClick={() => {
            {
              setIsExample(true);
              onHelpClick(photoId);
            }
          }}
        >
          <ViewIcon />
          <span>Ejemplo</span>
        </div>
      ) : undefined}
      {photoDefinition.useGallery ? (
        <input
          id={htmlId}
          type="file"
          accept="image/*,video/*"
          onChange={(e) => onChange(photoId, e)}
        />
      ) : (
        <input
          id={htmlId}
          type="file"
          accept="image/*"
          capture="environment"
          onChange={(e) => onChange(photoId, e)}
        />
      )}
    </div>
  );
}

function FullScreenPreview({
  url,
  onRemove,
  onExit,
}: {
  url: string;
  onRemove: any;
  onExit: any;
}): JSX.Element {
  return (
    <div className="full-screen-preview">
      <img className="preview-photo" src={url} alt="Photo preview" />
      <div className="action-buttons">
        <button
          className="button button-danger button-block"
          onClick={onRemove}
        >
          Eliminar
        </button>
        <button className="button button-block" onClick={onExit}>
          Ok
        </button>
      </div>
    </div>
  );
}

export const vehicleZones: VehicleSide[] = [
  {
    id: MediaId.VEHICLE_EXTERIOR_FRONT,
    name: 'FRONTAL',
    sideValidation: 'FRONT-SIDE',
  },
  {
    id: MediaId.VEHICLE_EXTERIOR_RIGHT,
    name: 'LATERAL PASAJERO',
    sideValidation: 'RIGHT-SIDE',
  },
  {
    id: MediaId.VEHICLE_EXTERIOR_RIGHT_FRONT,
    name: 'LATERAL PASAJERO FRONTAL',
    sideValidation: 'RIGHT-FRONT-SIDE',
  },
  {
    id: MediaId.VEHICLE_EXTERIOR_RIGHT_BACK,
    name: 'LATERAL PASAJERO TRASERO',
    sideValidation: 'RIGHT-BACK-SIDE',
  },
  {
    id: MediaId.VEHICLE_EXTERIOR_LEFT,
    name: 'LATERAL CONDUCTOR',
    sideValidation: 'LEFT-SIDE',
  },
  {
    id: MediaId.VEHICLE_EXTERIOR_LEFT_FRONT,
    name: 'LATERAL CONDUCTOR FRONTAL',
    sideValidation: 'LEFT-FRONT-SIDE',
  },
  {
    id: MediaId.VEHICLE_EXTERIOR_LEFT_BACK,
    name: 'LATERAL CONDUCTOR TRASERO',
    sideValidation: 'LEFT-BACK-SIDE',
  },
  {
    id: MediaId.VEHICLE_EXTERIOR_BACK,
    name: 'TRASERO',
    sideValidation: 'BACK-SIDE',
  },
];

interface props {
  idSection?: string;
  onPreviewClick?: any;
}

const PhotoSectionScreen = ({
  idSection,
  onPreviewClick,
}: props): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { state, dispatch } = useContext(AppContext) as IAppContext;
  const { preInspectionId } = state.preInspectionModel;
  const history = useHistory();
  const [showSectionHelp, setShowSectionHelp] = useState(true);
  const [helpModalId, setHelpModalId] = useState<MediaId | null>(null);
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] =
    useState<ValidationError | null>(null);
  const [previewPhoto, setPreviewPhoto] = useState<Photo | null>(null);
  const [currentPhotoToAnalysis, setCurrentPhotoToAnalysis] = useState<
    string | null
  >(null);
  const [currentFileToKeep, setCurrentFileToKeep] = useState<any>(null);
  const [currentMediaSectionId, setCurrentMediaSectionId] =
    useState<MediaId | null>(null);
  const [showValidationConfirm, setShowValidationConfirm] = useState(false);
  const [showZoneValidation, setShowZoneValidation] = useState(false);
  const [descZoneValidation, setDescZoneValidation] = useState('');
  const [alertMessage, setAlertMessage] = useState({
    alertMessage: '',
    alertType: '' as AlertType,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [showAlertWhenAreNotValid, setShowAlertWhenAreNotValid] =
    useState(false);
  const [showMissingZones, setShowMissingZones] = useState<boolean>(false);
  const [missingZone, setMissingZone] = useState<string>('');
  const [isExample, setIsExample] = useState<boolean>(false);
  const sectionId = idSection ?? id;
  const photoSectionId = photoSectionIdFromUrlSlug(sectionId);
  const photoSection = FILTER_PHOTO_SECTIONS(
    photoSectionId,
    state.preInspectionModel.prePlanType,
  );
  const photos = photoSection.photoIds.map(
    (mediaId) => state.photos[mediaId],
  ) as unknown as Photo[];
  const requiredPhotos = photoSection.photoIds
    .filter(
      (mediaId) =>
        PHOTO_DEFINITIONS[mediaId].isRequired === true &&
        isValidPhotoSectionsByBrand(
          mediaId,
          state.preInspectionModel,
          PHOTO_DEFINITIONS[mediaId].isVisible,
          BRAND,
        ),
    )
    .map((mediaId) => state.photos[mediaId]);

  // Nuevas galerias
  const returnTypeZone = (section: MediaId) => {
    switch (section) {
      case MediaId.VEHICLE_EXTERIOR_BACK:
        return 'TRASERO';
      case MediaId.VEHICLE_EXTERIOR_FRONT:
        return 'FRONTAL';
      case MediaId.VEHICLE_EXTERIOR_LEFT:
        return 'LATERAL CONDUCTOR';
      case MediaId.VEHICLE_EXTERIOR_LEFT_FRONT:
        return 'LATERAL CONDUCTOR FRONTAL';
      case MediaId.VEHICLE_EXTERIOR_LEFT_BACK:
        return 'LATERAL CONDUCTOR TRASERO';
      case MediaId.VEHICLE_EXTERIOR_RIGHT:
        return 'LATERAL PASAJERO';
      case MediaId.VEHICLE_EXTERIOR_RIGHT_FRONT:
        return 'LATERAL PASAJERO FRONTAL';
      case MediaId.VEHICLE_EXTERIOR_RIGHT_BACK:
        return 'LATERAL PASAJERO TRASERO';
      default:
        return null;
    }
  };

  const mapAlertTypeToSeverity = (
    alertType: AlertType,
  ): AlertProps['severity'] => {
    switch (alertType) {
      case 'success':
      case 'info':
      case 'warning':
      case 'error':
        return alertType;
      default:
        return undefined;
    }
  };

  const toggleSectionHelp = () => {
    setShowSectionHelp(!showSectionHelp);
  };

  const onChange = async (
    mediaId: MediaId,
    event: React.ChangeEvent<HTMLInputElement>,
    skipValidation?: boolean,
    photoAttemptId?: string,
    forceUpload?: boolean,
  ) => {
    if (!event.target.files || !state.preInspectionId) {
      return;
    }

    setInProgress(true);
    setLoading(true);
    try {
      const isOnline = await isNetOnline();

      if (!isOnline) {
        console.warn('Not internet connection.');
        setAlertMessage({
          alertMessage:
            'Hemos detectado problemas con tu internet. Verifica tu conexión e intenta de nuevo.',
          alertType: 'warning',
        });

        setShowAlert(true);
        setInProgress(false);
        setLoading(false);

        return;
      } else {
        setShowAlert(false);
      }

      //Get the current photo
      const currentPhotos = { ...state.photos };
      const photoUniqueId = uuidv4();
      const isActiveLocation = ![Company.SURA.toString()].includes(BRAND);

      // Get the local URL from the selected image
      const localImageUrl = URL.createObjectURL(event.target.files[0]);
      // Show the image in the async mode
      dispatch({
        type: ActionType.ADD_PHOTO,
        payload: {
          id: mediaId,
          uniqueId: photoAttemptId ? photoAttemptId : photoUniqueId,
          url: localImageUrl,
        },
      });

      const photoToProcess = Array.isArray(currentPhotos[mediaId])
        ? ((currentPhotos[mediaId] as Photo[]).slice(-1)[0] as Photo)
        : (currentPhotos[mediaId] as Photo);

      //Check location again
      if (isActiveLocation) {
        if ('geolocation' in navigator) {
          navigator.geolocation.watchPosition(
            (position) => {
              dispatch({
                type: ActionType.SET_PREINSPECTION_GEOLOCATION,
                payload: {
                  lat: String(position.coords.latitude),
                  lng: String(position.coords.longitude),
                },
              });
            },
            () => {
              console.warn('Can not get the current location.');
            },
            {
              enableHighAccuracy: true,
              maximumAge: 0, // Avoid using cached locations
            },
          );
        }
      }

      // Send the current photo to API
      const { awsUrl } = await uploadPhotoToServer(
        state.preInspectionId,
        mediaId,
        event,
        skipValidation,
      );

      // Request the validations for the image file
      console.time('Validations');
      const inactiveValidation = [Company.SURA.toString()].includes(BRAND);
      const requireMatch = BRAND === Company.INS;
      refreshGalleryOneScreen(dispatch);
      const [error, reduxAction] = await runValidations(
        mediaId,
        awsUrl,
        inactiveValidation,
        state.preInspectionModel.preVehiclePlate,
        requireMatch,
        event.target.files[0],
        forceUpload ?? false,
      );
      console.timeEnd('Validations');
      // Restablece la URL de la imagen después de completar el proceso
      dispatch({
        type: ActionType.UPDATE_PHOTO_URL,
        payload: {
          id: mediaId,
          url: awsUrl,
          uniqueId: photoAttemptId
            ? photoAttemptId
            : photoToProcess.attemps !== undefined &&
              photoToProcess.attemps !== 0 &&
              !photoToProcess.url
            ? photoToProcess.uniqueId
            : photoUniqueId,
        },
      });

      if (!error || (skipValidation !== undefined && skipValidation)) {
        dispatch({
          type: ActionType.ADD_PHOTO,
          payload: {
            id: mediaId,
            url: awsUrl,
            uniqueId: photoAttemptId
              ? photoAttemptId
              : photoToProcess.attemps !== undefined &&
                photoToProcess.attemps !== 0 &&
                !photoToProcess.url
              ? photoToProcess.uniqueId
              : photoUniqueId,
          },
        });

        if (reduxAction) {
          dispatch(reduxAction);
        }

        // Skip the validation if the user confirm use the photo
        if (skipValidation) {
          dispatch({
            type: ActionType.NOTVALID_PHOTO,
            payload: {
              id: mediaId,
              notValid: true,
              uniqueId: photoAttemptId ? photoAttemptId : photoUniqueId,
            },
          });

          dispatch({
            type: ActionType.ATTEMPT_PHOTO,
            payload: {
              id: mediaId,
              attemps: 0,
              uniqueId: photoAttemptId ? photoAttemptId : photoUniqueId,
            },
          });
        } else {
          if (photoToProcess.notValidZone === false) {
            dispatch({
              type: ActionType.NOTVALID_PHOTO,
              payload: {
                id: mediaId,
                notValid: false,
                uniqueId: photoUniqueId,
              },
            });

            dispatch({
              type: ActionType.ATTEMPT_PHOTO,
              payload: {
                id: mediaId,
                attemps: 0,
                isBlurry: false,
                uniqueId: photoUniqueId,
              },
            });
          }
        }

        // Stablish the section for analysis damage
        if (!inactiveValidation) {
          const sectionToAnalysis = returnTypeZone(mediaId);
          setCurrentPhotoToAnalysis(sectionToAnalysis);
        }
      } else {
        const attempsToTry =
          photoToProcess.attemps !== undefined
            ? Number(photoToProcess.attemps) + 1
            : 1;

        // Validate the attemps if the photo is not valid
        let limitAttemps: number =
          mediaId === MediaId.VEHICLE_EXTERIOR_PLATE ||
          mediaId === MediaId.VEHICLE_EXTERIOR_PLATE_CR
            ? 500
            : 2;

        // Validate the limit attemps by Error Type
        limitAttemps =
          error && error === ValidationError.NO_VEHICLE ? 500 : limitAttemps;
        limitAttemps =
          error && error === ValidationError.IS_BLURRY ? 3 : limitAttemps;

        if (
          attempsToTry > limitAttemps ||
          (attempsToTry > 1 && mediaId === MediaId.VEHICLE_INTERIOR_ODOMETER)
        ) {
          dispatch({
            type: ActionType.ATTEMPT_PHOTO,
            payload: {
              id: mediaId,
              attemps: 0,
              uniqueId: photoToProcess
                ? photoToProcess.uniqueId
                : photoUniqueId,
            },
          });

          await deletePhotoFromServer(awsUrl);
          await onChange(mediaId, event, true, photoToProcess.uniqueId);
        } else {
          await deletePhotoFromServer(awsUrl);
          setValidationError(error);
          // Restablece la URL de la imagen después de completar el proceso
          dispatch({
            type: ActionType.UPDATE_PHOTO_URL,
            payload: {
              id: mediaId,
              url: undefined,
              uniqueId: photoToProcess
                ? photoToProcess.uniqueId
                : photoUniqueId,
            },
          });
          dispatch({
            type: ActionType.ATTEMPT_PHOTO,
            payload: {
              id: mediaId,
              attemps: attempsToTry,
              isBlurry: error === ValidationError.IS_BLURRY,
              uniqueId: photoToProcess
                ? photoToProcess.uniqueId
                : photoUniqueId,
            },
          });
        }
      }
    } catch (error) {
      // TODO: Sentry.captureException(error);
      console.error(error);
      setAlertMessage({
        alertMessage:
          'Ocurrió un error no controlado, por favor intente nuevamente.',
        alertType: 'error',
      });
    } finally {
      setLoading(false);
      setInProgress(false);
    }
  };

  const removePhoto = async (id: MediaId, url?: string, uniqueId?: string) => {
    if (url) {
      await deletePhotoFromServer(url);

      if (BRAND === Company.IS && url.includes('VEHICLE_EXTERIOR')) {
        const beforeHasBeenNotify =
          localStorage.getItem('externalCarImagesIsBlurry') === 'true';
        if (beforeHasBeenNotify) {
          let countDeletesByInvalidImages = +(
            localStorage.getItem('countDeletesByInvalidImages') || '0'
          );
          countDeletesByInvalidImages += 1;
          localStorage.setItem(
            'countDeletesByInvalidImages',
            countDeletesByInvalidImages.toString(),
          );

          if (countDeletesByInvalidImages === 3) {
            localStorage.setItem('externalCarImagesIsBlurry', 'false');
            localStorage.setItem('countDeletesByInvalidImages', '0');
          }
        }
      }

      dispatch({
        type: ActionType.REMOVE_PHOTO,
        payload: { id: id, uniqueId: uniqueId },
      });
    }
    setPreviewPhoto(null);
  };

  const handleWarningButton = (): void => {
    setValidationError(null);
  };

  const handleForceUploadButton = (): void => {
    setValidationError(null);
    console.log('force upload', currentMediaSectionId);
  };

  const clearHelpId = (photoId: string) => {
    setHelpModalId(null);
    if (!isExample) {
      document.getElementById(`photo-${photoId}`)?.click();
    }
  };

  const handleKeepFile = async () => {
    if (currentMediaSectionId) {
      await onChange(currentMediaSectionId, currentFileToKeep, true);
      setShowValidationConfirm(false);
      setCurrentFileToKeep(null);
      setCurrentMediaSectionId(null);
    }
  };

  const handleCancelKeepFile = async () => {
    setLoading(true);
    setShowValidationConfirm(false);
    if (currentMediaSectionId) {
      const currentPhotos = { ...state.photos };
      const photoToProcess = currentPhotos[currentMediaSectionId];

      if (photoToProcess) {
        dispatch({
          type: ActionType.ATTEMPT_PHOTO,
          payload: {
            id: currentMediaSectionId,
            attemps: 0,
          },
        });

        setCurrentFileToKeep(null);
        setCurrentMediaSectionId(null);
      }
    }
    setLoading(false);
  };

  const handleResultAnalysisBySide = async (
    currentZone: string,
    state: IState,
  ) => {
    // Send to analisys for get damages
    console.log('INIT-DAMAGE-ANALYSIS');
    await sendToDamageAnalysis(state, currentZone);
  };

  const handleVehicleZonesIsValid = async (
    fromSubmit = false,
  ): Promise<boolean> => {
    let isValid = true;
    if (photoSectionId === PhotoSectionId.SURA_PHOTOS) {
      const countInvalidZone = (photos as unknown as Photo[]).filter(
        (f) =>
          f.notValidZone === true &&
          (f.notValid === undefined || f.notValid === false),
      );

      // If the external zones validation is success
      if (countInvalidZone.length === 0) {
        setShowZoneValidation(false);
        setDescZoneValidation('');

        if (fromSubmit) {
          isValid = handlePhotosIsValid();
        }
      } else {
        setDescZoneValidation(
          'Las imágenes resaltadas no corresponden a la imagen solicitada, por favor cargarlas nuevamente.',
        );
        setShowZoneValidation(true);

        isValid = false;
      }
    } else if (photoSectionId === PhotoSectionId.INTERIOR) {
      if (fromSubmit) {
        const result = await getIsValidZones(preInspectionId);
        if (
          result.isValid === false &&
          result.missingZone &&
          String(result.missingZone).length > 0
        ) {
          isValid = false;
          dispatch({
            type: ActionType.UPDATE_PHOTO_URL,
            payload: { id: result.missingZone as MediaId, url: undefined },
          });

          const zoneName = returnTypeZone(result.missingZone as MediaId);
          if (zoneName) {
            setMissingZone(zoneName);
            setShowMissingZones(true);
          } else {
            setMissingZone('');
            setShowMissingZones(false);
          }
        }
      }
    }

    return isValid;
  };

  const handlePhotosIsValid = (): boolean => {
    if (BRAND === Company.IS) {
      const beforeHasBeenNotify =
        localStorage.getItem('externalCarImagesIsBlurry') === 'true';
      const countExternalZoneIsNotValid = (photos as unknown as Photo[]).filter(
        (p: Photo) =>
          ((p.notValid && p.notValid === true) ||
            (p.isBlurry && p.isBlurry === true)) &&
          [
            PHOTO_DEFINITIONS.VEHICLE_EXTERIOR_FRONT,
            PHOTO_DEFINITIONS.VEHICLE_EXTERIOR_BACK,
            PHOTO_DEFINITIONS.VEHICLE_EXTERIOR_LEFT,
            PHOTO_DEFINITIONS.VEHICLE_EXTERIOR_RIGHT,
          ].includes(PHOTO_DEFINITIONS[p.id]),
      ).length;

      if (countExternalZoneIsNotValid >= 3 || beforeHasBeenNotify) {
        setShowAlertWhenAreNotValid(true);

        return false;
      } else {
        localStorage.setItem('externalCarImagesIsBlurry', 'false');
      }
    }

    dispatch({
      type: ActionType.SET_EXTERNAL_CAR_IMAGES_IS_BLURRY,
      payload: false,
    });

    return true;
  };

  const handleButtonContinue = () => {
    (async (): Promise<void> => {
      if (await handleVehicleZonesIsValid(true)) {
        history.goBack();
      }
    })();
  };

  const handleIsDisabled = (): boolean => {
    // Empty photos
    const hasEmptyPhotos =
      (requiredPhotos as unknown as Photo[]).every(
        (photo) =>
          photo !== undefined &&
          photo.url &&
          String(photo.url).toLowerCase().indexOf('awsaccesskeyid') > -1,
      ) === false;

    // Not valid photos
    const hasNoValidPhotos = (requiredPhotos as unknown as Photo[]).some(
      (photo) =>
        photo.notValidZone &&
        (photo.notValid === undefined || photo.notValid === false),
    );

    return hasEmptyPhotos || hasNoValidPhotos;
  };

  const handleIsBlurryButtonClose = () => {
    setShowAlertWhenAreNotValid(false);
  };

  const handleIsBlurryButtonContinue = () => {
    dispatch({
      type: ActionType.SET_EXTERNAL_CAR_IMAGES_IS_BLURRY,
      payload: true,
    });
    setShowAlertWhenAreNotValid(false);
    history.goBack();
  };

  const handleIsBlurryClose = (
    event: any,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason === 'backdropClick') {
      console.log(reason);
    } else {
      setShowAlertWhenAreNotValid(false);
    }
  };

  const handleBackdropClick = (event: any) => {
    //these fail to keep the modal open
    event.stopPropagation();

    return false;
  };

  const handleBackToMenuGallery = (): void => {
    if (handlePhotosIsValid()) {
      history.goBack();
    }
  };

  const helpContents = getHelpModalContents(helpModalId);

  useEffect(() => {
    (async (): Promise<void> => {
      if (
        currentPhotoToAnalysis !== null &&
        currentPhotoToAnalysis.length > 0
      ) {
        setCurrentPhotoToAnalysis(null);
        await handleResultAnalysisBySide(currentPhotoToAnalysis, state);
      }

      await handleVehicleZonesIsValid();
    })();
  });

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (inProgress) {
        const message =
          'Estás intentando salir de la página mientras se están subiendo imágenes. ¿Estás seguro?';
        event.returnValue = message;

        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [inProgress]);

  useEffect(() => {
    if (showAlert && alertMessage.alertType === 'success') {
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [showAlert, alertMessage.alertType]);

  return (
    <div className="photo-section-screen">
      {showZoneValidation && (
        <Alert
          className={clsx(
            'vh-side-alert',
            idSection !== undefined ? 'vh-side-alert-top' : '',
          )}
          severity="warning"
        >
          {descZoneValidation}
        </Alert>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={
          showZoneValidation
            ? 'vh-side-alert-upload'
            : 'vh-side-alert-upload-top'
        }
        open={showAlert}
      >
        <Alert
          onClose={
            alertMessage.alertType === 'success'
              ? () => setShowAlert(false)
              : undefined
          }
          severity={mapAlertTypeToSeverity(alertMessage.alertType)}
        >
          <div
            dangerouslySetInnerHTML={{ __html: alertMessage.alertMessage }}
          />
        </Alert>
      </Snackbar>
      {showSectionHelp && (
        <Modal onBackdropClick={toggleSectionHelp}>
          <h2>{photoSection.title}</h2>
          {photoSection.helpModal}
          <ContinueButton onClick={toggleSectionHelp}>Entendido</ContinueButton>
        </Modal>
      )}
      {helpModalId && helpContents && (
        <Modal
          onBackdropClick={() => clearHelpId(helpModalId)}
          className="photo-help-modal"
        >
          <h2>{helpContents.title}</h2>
          {helpContents.imgUrl && (
            <img
              src={helpContents?.imgUrl}
              alt={helpContents.title}
              height={200}
            />
          )}
          {helpContents.paragraph}
          <ContinueButton onClick={() => clearHelpId(helpModalId)}>
            Aceptar
          </ContinueButton>
        </Modal>
      )}

      <Dialog
        className="photo-help-modal"
        open={showValidationConfirm}
        onClose={() => setShowValidationConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Confirmar uso de Fotografía'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Hemos detectado varios intentos de actualización con esta
            fotografía.
            <br />
            <br />
            ¿Deseas confirmar el uso de esta fotografía?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <ContinueButton onClick={handleKeepFile}>
                Sí, guardar
              </ContinueButton>
            </Grid>
            <Grid item xs={6} md={6}>
              <Button onClick={handleCancelKeepFile}>No, cancelar</Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        className="photo-help-modal"
        open={showAlertWhenAreNotValid}
        onClose={handleIsBlurryClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onBackdropClick={handleBackdropClick}
      >
        <DialogTitle id="alert-dialog-title">
          {'Confirmar Fotografías'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              Hemos identificado que las fotografías externas del vehículo, son{' '}
              <b>imágenes no válidas</b>, ya sea por estar <b>borrosas</b> o por
              estar en <b>zonas no correspondientes</b>.
            </p>
            <p>¿Deseas continuar con el proceso?</p>

            <p className="detail">
              <i>
                <b>
                  NOTA: <u>Si procedes</u> con el uso de estas imágenes, el
                  proceso deberá pasar por otra etapa de validación.
                </b>
              </i>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="action-buttons">
          <div className="button-left">
            <Button onClick={handleIsBlurryButtonClose} autoFocus>
              No, cancelar
            </Button>
          </div>
          <div className="button-right">
            <ContinueButton onClick={handleIsBlurryButtonContinue}>
              Proceder
            </ContinueButton>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        className="photo-validation-modal"
        open={showMissingZones}
        onClose={() => setShowMissingZones(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Validación de Fotografías'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Hemos detectado que la fotografía en la zona <b>{missingZone}</b> no
            fue validada por el sistema.
            <br />
            <br />
            Por favor accede nuevamente a la sección de fotografías externas y
            toma de nuevo la zona recien indicada.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}></Grid>
            <Grid item xs={6} md={6}>
              <ContinueButton onClick={() => setShowMissingZones(false)}>
                Entendido
              </ContinueButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      {/* {loading && <Loading message="Validando Fotografía" />} */}
      {/** Maybe if we want show all messages for each photo, we will need to define
       *  estimated time for each one and have a variable to track the current photo to
       * change the duration */}
      {loading && <ProgressLoading />}
      {validationError && (
        <ValidationWarning
          validationError={validationError}
          handleWarningButton={handleWarningButton}
          handleForceUploadButton={handleForceUploadButton}
        />
      )}
      <div className="container">
        {!idSection && (
          <h3 className="lead">
            <BackIcon onClick={handleBackToMenuGallery} />
            <span>{PHOTO_SECTIONS[photoSectionId].title}</span>
            <InfoIcon onClick={toggleSectionHelp} />
          </h3>
        )}
        <div className="photos-scrollable-area">
          <div className="photos-container">
            {photos
              .filter((photo: Photo | Photo[]) => {
                if (Array.isArray(photo)) {
                  return true;
                }

                return isValidPhotoSectionsByBrand(
                  photo.id,
                  state.preInspectionModel,
                  PHOTO_DEFINITIONS[photo.id].isVisible,
                  BRAND,
                );
              })
              .map((photo: Photo | Photo[]) => {
                if (Array.isArray(photo)) {
                  return photo.map((p: Photo) => {
                    if (p.url) {
                      return (
                        <ImagePreviewThumbnail
                          key={p.uniqueId}
                          photo={p}
                          onPreviewClick={onPreviewClick ?? setPreviewPhoto}
                        />
                      );
                    }
                  });
                }
                if (photo.url) {
                  return (
                    <ImagePreviewThumbnail
                      key={photo.id}
                      photo={photo}
                      onPreviewClick={onPreviewClick ?? setPreviewPhoto}
                    />
                  );
                }
                if (photoSectionId !== PhotoSectionId.ACCESSORIES) {
                  return (
                    <ImageUploadThumbnail
                      key={photo.id}
                      photo={photo}
                      onChange={onChange}
                      setIsExample={setIsExample}
                      onHelpClick={setHelpModalId}
                    />
                  );
                }
              })}
            {photoSectionId === PhotoSectionId.ACCESSORIES && (
              <ImageUploadThumbnail
                key={MediaId.ACCESSORIES}
                media={MediaId.ACCESSORIES}
                onChange={onChange}
                onHelpClick={setHelpModalId}
                hasItems={(photos[0] as unknown as Photo[]).length > 0}
                setIsExample={setIsExample}
                hasExampleIcon={false}
              />
            )}
          </div>
        </div>
      </div>
      {!idSection && (
        <div className="button-panel">
          {/* TODO: Change history.goBack() to a navigation forward based on preinspectionsId */}
          <ContinueButton
            disabled={handleIsDisabled()}
            onClick={handleButtonContinue}
          >
            Continuar
          </ContinueButton>
        </div>
      )}

      {previewPhoto?.url && (
        <FullScreenPreview
          url={previewPhoto.url}
          onRemove={() =>
            removePhoto(
              previewPhoto.id,
              previewPhoto.url,
              previewPhoto.uniqueId,
            )
          }
          onExit={() => setPreviewPhoto(null)}
        />
      )}
    </div>
  );
};

export default PhotoSectionScreen;
