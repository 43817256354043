//CPE: Claim Processing Engine

import Axios, { AxiosInstance, AxiosStatic } from 'axios';

const CPE_API = process.env.REACT_APP_CPE_API;
const CPE_API_KEY = process.env.REACT_APP_CPE_API_KEY;

interface ClaimResponse {
  id: string;
  policyId: string;
  claimNumber: string;
  company: string;
  companyId: string;
  driverId: string;
  insuredIndividualId: string;
  driverRelationshipToInsured: string;
  claimFiler: string;
  claimBeneficiary: string;
  createdAt: string;
  updatedAt: string;
  completedAt: string;
  currentAgentId: string;
  status: string;
  currentStage: string;
  policyType: string;
  additionalNotes: string;
  poNumber: string;
}

interface StatusFiles {
  uploaded: string[];
  pending: string[];
}

interface ClaimDocumentStatusResponse {
  vehicleImage: StatusFiles;
  documents: StatusFiles;
}

interface ClaimAdditionalDocumentsResponse {
  id: string;
  claimId: string;
  documentType: string;
}

export interface CategoryLicensePlateResults {
  detection: {
    detected: boolean;
    boundingBox: any | null;
    confidence: number | null;
  };
  licensePlateNumber: string | null;
  metadata: {
    averageOcrConfidence: number;
    processedAt: string;
    timings: {
      imageProcessing: number;
      objectDetection: number;
      imageAugmentation: number;
      imageCropping: number;
      ocrProcessing: number;
      textAnalysis: number;
      totalProcessingTime: number;
    };
  };
}

export interface CategoryDriverLicenseResults {
  licenseNumber: string | null;
  fullName: string | null;
  dateOfBirth: string | null;
  residentialAddress: string | null;
  issueDate: string | null;
  expirationDate: string | null;
  issuingTerritory: string | null;
  metadata: {
    averageOcrConfidence: number;
    processedAt: string;
    timings: {
      imageProcessing: number;
      objectDetection: number;
      imageCropping: number;
      imageAugmentation: number;
      ocrProcessing: number;
      bedrockAnalysis: number;
      totalProcessingTime: number;
    };
  };
}

export interface CategoryVehicleResults {
  angle: string; // Detected vehicle angle
  probability: number; // Model confidence (0-100)
  metadata: {
    processedAt: string;
    timings: {
      imageProcessing: number;
      predictionProcessing: number;
      totalProcessingTime: number;
    };
  };
}

export interface CategoryVINResults {
  vinNumber: string | null;
  metadata: {
    averageOcrConfidence: number;
    processedAt: string;
    timings: {
      imageProcessing: number;
      grayscaleProcessing: number;
      ocrProcessing: number;
      textAnalysis: number;
      totalProcessingTime: number;
    };
  };
}

export interface CategoryMarbeteResults {
  marbeteNumber: string | null;
  metadata: {
    averageOcrConfidence: number;
    processedAt: string;
    timings: {
      imageProcessing: number;
      grayscaleProcessing: number;
      ocrProcessing: number;
      textAnalysis: number;
      totalProcessingTime: number;
    };
  };
}

export interface UploadImageResponse {
  filename: string;
  angle: string;
  probability: number;
  metadata: any;
}

export enum Categories {
  DRIVER_LICENSE = 'drivers_license',
  LICENSE_PLATE = 'license_plate',
  CAR_REGISTRATION = 'car_registration',
  VEHICLE = 'vehicle',
  ODOMETER = 'odometer',
  VIN_NUMBER = 'vin_number',
  VEHICLE_DAMAGE = 'vehicle_damage',
  VEHICLE_TRUNK = 'vehicle_trunk',
  VEHICLE_HOOD = 'vehicle_hood',
  VEHICLE_INTERIOR = 'vehicle_interior',
  MARBETE = 'marbete',
  ADDITIONAL_DOCUMENTS = 'additional_documents',
  OTHER = 'other',
}

export enum Angles {
  left = 'Left',
  leftFront = 'Left_Front',
  right = 'Right',
  rightFront = 'Right_Front',
  front = 'Front',
  rear = 'Rear',
  rearLeft = 'Rear_Left',
  rearRight = 'Rear_Right',
}

export default class CpeClient {
  private apiClient: AxiosInstance;

  constructor(private axios: AxiosStatic = Axios) {
    this.apiClient = this.axios.create({
      baseURL: CPE_API,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${CPE_API_KEY}`,
      },
    });
  }

  async getClaim(poNumber: string): Promise<ClaimResponse[]> {
    const response = await this.apiClient.get(
      `/v1/claims?poNumber=${poNumber}`,
    );

    return response.data;
  }

  async getDocumentsStatus(
    claimId: string,
  ): Promise<ClaimDocumentStatusResponse> {
    const response = await this.apiClient.get(
      `/v1/claims/${claimId}/documents/status`,
    );

    return response.data;
  }

  async getAdditionalDocuments(
    claimId: string,
  ): Promise<ClaimAdditionalDocumentsResponse[]> {
    const response = await this.apiClient.get(
      `/v1/claims/${claimId}/additionalDocuments`,
    );

    return response.data;
  }

  async sendImageValidation(
    images: File[],
    category: Categories,
    forceUpload: boolean,
  ): Promise<UploadImageResponse> {
    const formData = new FormData();
    images.forEach((image) => formData.append('images', image));
    formData.append('category', category);

    const endpoint = forceUpload
      ? '/v1/aiAnalysis?forceUpload=true'
      : '/v1/aiAnalysis';

    const response = await this.apiClient.post(endpoint, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });

    return response.data;
  }

  async deleteImage(claimId: string, imageId: string): Promise<any> {
    const response = await this.apiClient.delete(
      `/v1/claims/${claimId}/images/${imageId}`,
    );

    return response.data;
  }
}
