import { PredictionAPIClient } from '@azure/cognitiveservices-customvision-prediction';
import { ClassifyImageResponse } from '@azure/cognitiveservices-customvision-prediction/esm/models';
import { ApiKeyCredentials } from '@azure/ms-rest-js';

export interface CustomVisionConfig {
  predictionKey?: string;
  predictionEndpoint?: string;
  projectId?: string;
  publishedModelName?: string;
}

export interface PredictionResult {
  tagName: string;
  probability: number;
}

export class AzureCustomVisionProvider {
  private predictionClient: PredictionAPIClient;
  private projectId: string;
  private publishedModelName: string;
  private readonly CONFIDENCE_THRESHOLD = 0.9;

  constructor(config: CustomVisionConfig = {}) {
    const predictionKey =
      config.predictionKey || process.env.REACT_APP_AZURE_PREDICTION_KEY;
    const predictionEndpoint =
      config.predictionEndpoint ||
      process.env.REACT_APP_AZURE_PREDICTION_ENDPOINT;
    const projectId =
      config.projectId || process.env.REACT_APP_AZURE_PROJECT_ID;
    const publishedModelName =
      config.publishedModelName || process.env.REACT_APP_AZURE_MODEL_NAME;

    if (
      !predictionKey ||
      !predictionEndpoint ||
      !projectId ||
      !publishedModelName
    ) {
      throw new Error(
        'Missing required Azure Custom Vision credentials. Please check your environment variables or config.',
      );
    }

    const credentials = new ApiKeyCredentials({
      inHeader: { 'Prediction-Key': predictionKey },
    });

    this.predictionClient = new PredictionAPIClient(
      credentials,
      predictionEndpoint,
    );
    this.projectId = projectId;
    this.publishedModelName = publishedModelName;
  }

  async getPrediction(imageBuffer: Buffer): Promise<PredictionResult[]> {
    if (!Buffer.isBuffer(imageBuffer)) {
      throw new Error("El parámetro 'imageBuffer' no es un Buffer válido");
    }

    let result: ClassifyImageResponse | undefined = undefined;
    try {
      result = await this.predictionClient.classifyImage(
        this.projectId,
        this.publishedModelName,
        imageBuffer,
      );
    } catch (error: any) {
      console.error('Error al clasificar la imagen:', error.message);
      console.error('Detalles del error:', error.response?.body);
    }

    if (!result?.predictions) {
      return [];
    }

    console.log(result.predictions.slice(0, 3));

    return result.predictions
      .filter(
        (prediction: any) =>
          prediction.probability !== undefined &&
          prediction.probability >= this.CONFIDENCE_THRESHOLD,
      )
      .map((prediction: any) => ({
        tagName: prediction.tagName || '',
        probability: prediction.probability || 0,
      }));
  }

  async batchPredict(imageBuffers: Buffer[]): Promise<PredictionResult[][]> {
    return Promise.all(
      imageBuffers.map((buffer) => this.getPrediction(buffer)),
    );
  }
}
